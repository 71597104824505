import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule } from '../modules/shared/shared.module';
import { DossiersService } from '../services/dossiers/dossiers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dossiers_Data } from '../interfaces/Dossiers';
import { SharedService } from '../services/shared/shared.service';
import { of, switchMap } from 'rxjs';
import { UploadService } from '../services/upload/upload.service';
import { NoTagFichier } from '../helpers/functions/NoTagFichier';

@Component({
  selector: 'app-transmettre',
  standalone: true,
  imports: [SharedModule],
  template: `
    <form class="transmettre-form" [formGroup]="form">
        <h5>Transmettre Message / Document(s)</h5>
        <div class="d-flex">
            <div class="col-6 pe-5">

                <div class="form-check form-check-inline my-1">
                    <input class="form-check-input form-control cursor checkBoxMessages" type="checkbox" #checkBoxMessages (click)="toggleVisiblity('message')">
                    <label class="form-check-label bold cursor" (click)="checkBoxMessages.click()">Je souhaite transmettre un message</label>
                </div>

                <div *ngIf="sendMessage">
                    <div class="my-2">
                        <div>Objet</div>
                        <input 
                            type="text" 
                            formControlName="objet" 
                            class="form-control" 
                            (input)="isButtonDisabled()" 
                            [maxLength]="inputLimit.get('objet')" 
                            [ngClass]="form.get('objet')?.value.length >= inputLimit.get('objet') ? 'red': ''">
                        <p>
                            <small class="float-start grey">255 caractères maximum</small>
                            <span class="float-end" [ngClass]="form.get('objet')?.value.length >= inputLimit.get('objet') ? 'red': 'blue'">
                                {{ form.get('objet')?.value.length }}
                                {{ form.get('objet')?.value.length > 1 ? ' caractères': ' caractère' }}
                            </span>
                        </p>
                    </div>

                    <div class="mt-4 mb-2">
                        <div>Votre message</div>
                        <textarea class="form-control input-message" formControlName="message" (input)="isButtonDisabled()"></textarea>
                    </div>
                </div>
            </div>

            <div class="col-6 px-4">
                <div class="form-check form-check-inline my-1">
                    <input class="form-check-input form-control cursor checkBoxDocuments" type="checkbox" #checkBoxDocuments (click)="toggleVisiblity('document')">
                    <label class="form-check-label bold cursor" (click)="checkBoxDocuments.click()">Je souhaite transmettre / joindre un ou plusieurs documents</label>
                </div>

                <div class="my-2 blue bold" *ngIf="documentList.length > 0">
                    Nombre de document@if(documentList.length > 1){s} à transmettre : {{ documentList.length }} 
                </div>

                <div *ngIf="sendDocument">

                    <!-- Ne pas supprimer, cette zone de texte pourra être utilisée pour donner des informations aux utilisateurs de la part de la MDE -->
                    <!-- <small class="d-block small">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis repellendus ullam magni?</small> -->

                    <div class="liste-documents ps-3 pe-5 py-2" [ngClass]="documentList.length > 0 ? '' : 'd-none'">
                        @for (item of documentList; track $index) 
                        {
                            <div class="d-flex col-12 justify-content-between">
                                {{ item.name }}
                                <img class="align-self-end cursor" src="/assets/icons/picto-croix-rouge.png" height="10" alt="" (click)="removeDocument($index)">
                            </div>
                            <hr>
                        }
                    </div>
    
                    <div class="drag-and-drop-zone mt-3 mb-3 position-relative" (click)="fileUpload.click()">
                        <div class="drag-and-drop-text">Glissez-déposez vos fichiers dans cette zone</div>
                        <input class="opacity-0 z-3 h-100 w-100 bg-danger position-absolute cursor" type="file" #fileUpload multiple accept="{{allowedTypes}}" (change)="onChangeFile($event)">
                    </div>
    
                    <div class="input-group">
                        <input type="text" class="form-control p-2" [value]="fileName" readonly>
                        <button class="btn white bg-blue-dark-1" type="button" (click)="fileUpload.click()">Parcourir</button>
    
                        <button class="ms-2 white btn bg-blue-dark-1 rounded-0" type="button" (click)="addToDocumentList()">Ajouter le fichier</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="transmettre-button cursor" 
                (click)="transmettreMessage()" 
                [ngClass]="isDisabled ? 'bg-grey grey-dark not-allowed': 'bg-blue white cursor'"
                [disabled]="isDisabled">
            Transmettre
            </button>
        </div>
    </form>
  `,
  styles: 
  `
    .form-control,
    .liste-documents,
    .drag-and-drop-zone
    {
        border: 2px solid #BCBCBC;
    }

    .transmettre-form
    {
        border: 1px solid #BCBCBC;
        padding: 1rem;
        color: #828282;
    }

    .input-message
    {
        height: 11rem;
    }

    .input-destinataires
    {
        margin-top: 0.3rem;
        height: 4rem;
    }

    .form-control
    {
        padding: 0;
        border-radius: 0;
    }

    .liste-documents
    {
        height: 9rem;
        overflow-y: scroll;
        scrollbar-color: #292929 white;
        scrollbar-width: thin;
    }

    .drag-and-drop-zone
    {
        height: 7rem;
        padding: 1.5rem 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drag-and-drop-text
    {
        border: 2px dashed #BCBCBC;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .transmettre-button
    {
        padding: 0.7rem 1.7rem;
        border: 0;
    }
  `
})

export class TransmettreComponent 
{
  date:Date = new Date()
  formatedDate = this.date.toLocaleDateString().split("/").join('-')
  @Input() data!:Dossiers_Data
  @Input() ref!:string
  @Input() id_dossier!:number

  fileName:string = ""
  documentList:File[] = []
  multipleDocument:File[] = []
//   liste_destinataires:string[] = []
  sendMessage:boolean = false
  sendDocument:boolean = false
  addDestinataires:boolean = false
  form!: FormGroup
  idUser!:number
  isDisabled:boolean = true
  isSubmitted:boolean = false
  list_documents_id:{ID:number}[] = []
  readonly allowedTypes:string[] = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'image/png', 'image/jpeg', 'image/jpg', 'zip', 'application/octet-stream', 'application/zip', 'application/x-zip', 'application/x-zip-compressed']
  readonly maxSize = 30 * 1024 * 1024
  readonly maxTotalSize = 100 * 1024 * 1024
  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("objet", 255)
  )

  constructor
  (
    private dossiersService: DossiersService,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private uploadService: UploadService
  )
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    this.form = new FormGroup({
        objet: new FormControl(""),
        message: new FormControl(""),
        // liste_destinataires: new FormControl(this.liste_destinataires),
        liste_pj: new FormControl(this.documentList)
    })
  }

  onChangeFile(event:Event)
  {
    this.fileName = ""
    this.multipleDocument = []
    const errorType:string[] = []
    const errorSize:string[] = []
    const errorTotalSize:string[] = []
    const inputElement = event.target as HTMLInputElement
    
    if( inputElement.files && inputElement.files.length > 0)
    { 
        const totalSize = Array.from(inputElement.files).reduce((accumulator, file) => {
            return accumulator + file.size
          }, 0)

        if( totalSize >= this.maxTotalSize )
        {
            const fileList = Array.from(inputElement.files).map(file => {
                return `fichier:${file.name}, taille:${(file.size / 1024 / 1024).toFixed(2)} Mo \n`
            })

            fileList.map(file => errorTotalSize.push(file))
        }

        if(errorTotalSize.length > 0)
        {
            alert(`La somme de la taille des fichiers suivants excède 100 Mo.\n Veuillez réduire le nombre de documents ou utiliser un format de fichier plus compressé : \n ${errorTotalSize.toString().replaceAll(",","\n")}`)
            return
        }

        const arrayFile = Array.from(inputElement.files)

        arrayFile.forEach(element => {
            if(!this.allowedTypes.includes(element.type))
            {
                errorType.push(element.name)
                return
            }

            if(element.size > this.maxSize)
            {
                errorSize.push(element.name)
                return
            }

            const [fileName, extension] = element.name.split(".")
            const newFileName = NoTagFichier(fileName).concat(`.${extension}`)
            const newFile = new File([element], newFileName, {type: element.type})

            this.fileName += ` "${newFileName}" `
            this.multipleDocument.push(newFile)
        })

        if( errorType.length !== 0) alert(`Les fichiers : \n ${errorType.toString().replaceAll(",","\n")} \n ne seront pas pris en compte car ils ne correspondent pas aux types de fichiers autorisés`)
        if( errorSize.length !== 0) alert(`Les fichiers ci-dessous : \n ${errorSize.toString().replaceAll(",","\n")} \n ne seront pas transmis car ils possèdent une taille supérieure à 30 Mo`)
    }
  }

  addToDocumentList()
  {
    if(this.fileName == "") return

    this.multipleDocument.forEach(document => {
        if(!this.documentList.includes(document)) this.documentList.push(document)  
    })

    this.fileName = ""
    this.isButtonDisabled()
  }

  removeDocument(index:number)
  {
    if (index > -1) 
    { 
        this.documentList.splice(index, 1)
    }
    this.isButtonDisabled()
  }

//   addToDestinatairesList(value:string)
//   {
//     if( value == "" ) return

//     value.split(/[;,\n\r]/).every((e,index) => {
//         if(index > (this.limite_destinataire - 1)) return false 
//         e = e.trim()
//         if( e.length == 0  || e == "" || !this.isValidEmail(e) ) return
//         this.liste_destinataires.push(e)
//         return true
//     })

//     this.liste_destinataires = this.liste_destinataires.slice(0, this.limite_destinataire)
//   }

//   removeDestinataire(index:number)
//   {
//     if (index > -1) 
//     { 
//         this.liste_destinataires.splice(index, 1)
//     }
//   }

  toggleVisiblity(type:string)
  {
    if(type === "message")
    {
        this.sendMessage = !this.sendMessage
    } 
    else if(type === "document") 
    {
        this.sendDocument = !this.sendDocument
        if(this.sendDocument == false) this.documentList = []
    }
    // else if(type === "destinataire") 
    // {
    //     this.addDestinataires = !this.addDestinataires

    //     if(this.addDestinataires == false) this.liste_destinataires = []
    // }
    this.isButtonDisabled()
  }
  
  isButtonDisabled()
  {
    const form = this.form.value

    if( 
        (!this.sendMessage && !this.sendDocument)
        || (this.sendMessage && (form.objet.length === 0 || form.message.length === 0))
        || (this.sendDocument && this.documentList.length === 0)
    )
    {
        this.isDisabled = true
    }
    else  this.isDisabled = false
  }

  isValidEmail(email:string)
  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
  }

  resetForm()
  {
    this.form.get("objet")?.patchValue("")
    this.form.get("message")?.patchValue("")
    this.form.get("liste_pj")?.setValue([])

    this.isDisabled = true
    if(this.sendMessage) (document.querySelector(".checkBoxMessages") as HTMLInputElement).click()
    if(this.sendDocument) (document.querySelector(".checkBoxDocuments") as HTMLInputElement).click()

    this.isButtonDisabled()
  }

  async transmettreMessage()
  {
    this.form.get("liste_pj")?.setValue(this.documentList)
    
    const form = this.form.value

    // if( form.objet.length === 0 && form.message.length === 0 && form.liste_destinataires.length === 0 && form.liste_pj.length === 0 ) return
    if( form.objet.length === 0 && form.message.length === 0 && form.liste_pj.length === 0 ) return
    
    const id_dossier = this.id_dossier ? this.id_dossier : Number(this.sharedService.getParam('id_dossier'))

    const folder = "dossiers"
    
    const formData = new FormData()

    let id_list:{ID:number}[] = []

    // Si il n'y a pas de pièces jointes le formData sera vide
    form.liste_pj.map((file:File, index:number) => {
        formData.append(`file${index}`, file)
    })

    this.isDisabled = true
    
    this.sharedService.blurPage()

    ;(await this.uploadService.uploadFile(formData, folder, this.ref, this.idUser, id_dossier))
    .pipe(
        switchMap((list_documents_id:{ID:number}[]) => {
            id_list = list_documents_id
            return this.dossiersService
                    .postMessage({
                        objet: form.objet.slice(0,255),
                        message: form.message,
                        // liste_destinataires: form.liste_destinataires.slice(0, this.limite_destinataire),
                        liste_pj: list_documents_id,
                        ref: this.ref,
                        idUser: this.idUser
                    })
        }),
        switchMap(ids => {
            if( form.liste_pj.length == 0 ) return of(ids)
            return this.dossiersService.updateDocument(id_list.map(x => x.ID), ids.map((x:{INSERTED_ID: number}) => x.INSERTED_ID))
        })
    )
    .subscribe({
        next: (ids) => 
        {
            if(ids)
            {
                this.resetForm()

                this.snackBar.open("Votre demande est transmise", "Ok", {
                    duration: 5000,
                })
    
                this.sharedService.postTableConnexion(this.idUser, 499, this.idUser).subscribe()

                this.sharedService.focusPage()
                this.isDisabled = false
            }
        },
        error: (e:Error) => 
        {
            console.log(e)

            this.snackBar.open("Une erreur est survenue lors de la transmission de votre message", "J'ai compris", {
                duration: 5000,
            })
        }
    })
  }
}
